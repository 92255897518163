.tree-select-dropdown-wrapper {
  width: 100%;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  padding: 8px;
}

.tree-select-dropdown-wrapper .ant-select-tree {
  font-weight: 400;
  font-size: 16px;
}

.tree-select-dropdown-wrapper .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #eff2ff;
  color: #0f0e98;
}

@media screen and (max-width: 480px) {
  .tree-select-dropdown-wrapper {
    padding: 4px;
  }

  .tree-select-dropdown-wrapper .ant-select-tree {
    font-size: 12px;
  }
}
