.login-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 40px;
  background: #1b3de6;
  border-radius: 4px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  border: 0px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.login-form-button:hover {
  background-color: #0f0e98;
  border-color: #0f0e98;
}
