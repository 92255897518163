.image-crop-wrapper .controls {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.image-crop-wrapper .quick-select-modal-sub-heading {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}

.image-crop-wrapper .slider {
  padding: 22px 0px;
}

.image-crop-wrapper .zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 2px solid #1b3de6;
  border-bottom: 2px solid #1b3de6;
  background: #1b3de6;
  width: 100%;
}

.image-crop-wrapper .crop-area-container {
  height: 350px;
}

.image-crop-wrapper .zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.image-crop-wrapper .zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.image-crop-wrapper .controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.image-crop-wrapper .controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.image-crop-wrapper .crop-container .reactEasyCrop_Contain {
  z-index: -1;
}

.image-crop-wrapper .image-ratio-text {
  color: #222732;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.image-crop-wrapper .image-ratio-text .ant-radio-inner::after {
  background-color: #1b3de6;
}

.image-crop-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #1b3de6;
}
