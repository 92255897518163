@import '~antd/dist/antd.css';
/* 
  BreakPoints:
    480px
    575px
    768px
    900px
    1024px
*/

* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  --primary-white: #ffffff;
  --content-neutral-primary: #222732;
  --content-neutral-secondary: #646d7b;
}

body {
  font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.005);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfd7ff;
  border-radius: 34px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1b3de6;
}

.loader-grid {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

body.inheritCursors * {
  cursor: inherit !important;
}

/* Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.unsaved-changes-popup .ant-modal-confirm-title {
  padding: 28px;
}

@media screen and (max-width: 480px) {
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 575px) {
  .ant-input,
  .ant-input-number,
  .ant-select,
  .ant-cascader,
  .ant-time-picker,
  .ant-calendar,
  .ant-slider,
  .ant-switch,
  .ant-radio-button,
  .ant-checkbox,
  textarea[class^='ant-'],
  input[class^='ant-'],
  .text-editor .ql-container {
    font-size: 16px !important;
  }
  .ant-form-item-label > label {
    font-size: 16px !important;
  }
}
