.back-to-login .back-to-login-icon {
  color: #1b3de6;
  width: 24px;
  height: 24px;
}
.forgot-password-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 32px;
  position: relative;
}

.back-to-login .back-to-login-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0f0e98;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 17px;
}
.back-to-login {
  cursor: pointer;
}
.forgot-password-reset-code-button-form-item {
  margin-bottom: 0px;
}

.forgot-password-form {
  max-width: 438px;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.forgot-password-form .forgot-password-form-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
}

.forgot-password-form .forgot-password-reset-my-password {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1b3de6;
  flex: none;
  order: 0;
  flex-grow: 0;
}
