@import '~antd/dist/antd.css';

.login-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 32px;
  position: relative;
}
.login-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 0;
  max-width: 438px;
}

.login-form {
  max-width: 438px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.login-form-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
}

.reset-my-password {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1b3de6;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.login-remember-me {
  margin-left: 8px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
}
