.responsvie-list-wrapper-class .ant-list-vertical .event-list-item-wrapper .ant-list-item-meta-title {
  width: fit-content;
}

.responsvie-list-wrapper-class .event-list-item-wrapper {
  border-bottom: none;
}

.responsvie-list-wrapper-class .event-list-item-wrapper:hover {
  background-color: #f9faff;
}
.responsvie-list-wrapper-class .event-list-image-wrapper {
  position: relative;
}
.responsvie-list-wrapper-class .event-list-image {
  height: 104px;
  border-radius: 4px;
}
.responsvie-list-wrapper-class .event-list-title {
  display: flex;
  align-items: center;
}
.responsvie-list-wrapper-class .event-list-title-heading {
  font-weight: 600;
  font-size: 12px;
  color: #222732;
  white-space: nowrap;
}
.responsvie-list-wrapper-class .event-list-description {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 4px;
}

.responsvie-list-wrapper-class .event-list-description-name {
  font-weight: 700;
  color: #222732;
}
.responsvie-list-wrapper-class .event-list-description-place {
  font-weight: 400;
  color: #646d7b;
  width: max-content;
}

.responsvie-list-wrapper-class .event-list-status {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  color: #646d7b;
}
.responsvie-list-wrapper-class .event-status-list-item {
  text-align: right;
}

.event-list-item-wrapper .event-list-options-responsive {
  display: none;
}

.responsvie-list-wrapper-class .event-list-item-meta {
  display: flex;
  align-items: center;
}
.responsvie-list-wrapper-class ul li {
  cursor: pointer;
}

.responsvie-list-wrapper-class .event-list-item-meta .ant-list-item-meta-content {
  max-width: 341px;
}

.responsvie-list-wrapper-class .event-status-list-item-title-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.responsvie-list-wrapper-class .event-status-list-item-title-container .artsdata-link-outlined-icon {
  background-color: #e3e8ff;
  color: #0f0e98;
  min-height: 32px;
  font-size: 12px;
  font-weight: 600;
  width: 24px;
  padding: 4px;
  border-radius: 4px;
  display: grid;
  place-content: center;
}

.responsvie-list-wrapper-class .event-status-list-item-title-container .artsdata-link-outlined-icon > span {
  display: grid;
  place-content: center;
}

.responsvie-list-wrapper-class .event-list-item-meta .image-featured-badge {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 32.04px;
  height: 32.04px;
  background-color: #1b3de6;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.responsvie-list-wrapper-class .event-list-description-name-container {
  display: flex;
  gap: 8px;
}

.responsvie-list-wrapper-class .event-list-more-icon:hover {
  background-color: #eff2ff;
}

.responsvie-list-wrapper-class .event-list-status {
  align-items: flex-end;
}

.responsvie-list-wrapper-class .ant-list-vertical .event-list-status {
  align-items: flex-start;
}

@media only screen and (max-width: 1024px) {
  .responsvie-list-wrapper-class .event-list-title-heading {
    white-space: nowrap;
  }

  .responsvie-list-wrapper-class .event-list-description-place {
    width: auto;
  }
}
@media only screen and (max-width: 768px) {
  .responsvie-list-wrapper-class {
    width: calc(100% - 16px);
  }

  .responsvie-list-wrapper-class .ant-list-item-meta-avatar {
    display: none;
  }

  .responsvie-list-wrapper-class .event-list-item-wrapper {
    margin-top: 8px;
    margin-bottom: 8px;
    gap: 8px;
  }
  .event-list-item-wrapper .event-list-options-responsive {
    display: block;
  }
  .event-list-item-wrapper .ant-list-item-extra {
    margin: 0;
    align-self: center;
    font-size: 24px;
  }
  .responsvie-list-wrapper-class .event-list-description-place {
    width: auto;
  }

  .responsvie-list-wrapper-class .event-status-list-item-title-container {
    width: fit-content;
  }

  .responsvie-list-wrapper-class .ant-list-item-action {
    display: none;
  }
}

@media only screen and (min-width: 575px) {
  .responsvie-list-wrapper-class .event-list-item-wrapper {
    gap: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .responsvie-list-wrapper-class .event-list-status-created-by {
    text-align: left;
    width: fit-content;
  }

  .responsvie-list-wrapper-class .event-list-status-updated-by {
    text-align: left;
    width: fit-content;
  }
}

@media only screen and (max-width: 480px) {
  .responsvie-list-wrapper-class .event-list-description-place {
    font-size: 12px;
  }

  .responsvie-list-wrapper-class .event-list-status-created-by,
  .responsvie-list-wrapper-class .event-list-status-updated-by {
    font-size: 10px;
  }

  .responsvie-list-wrapper-class .event-list-description-name {
    font-size: 16px;
  }

  .responsvie-list-wrapper-class .ant-list-item-extra > span {
    font-size: 20px !important;
  }

  .responsvie-list-wrapper-class .artsdata-tag-wrapper {
    font-size: 10px;
    padding: 2px 4px;
  }
}

@media screen and (max-width: 320px) {
  .responsvie-list-wrapper-class .ant-list-vertical .ant-list-item {
    flex-wrap: wrap;
    gap: 0px;
  }
}
