.taxonomy-listing-wrapper .events-heading {
  color: var(--content-neutral-primary, #222732);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.taxonomy-listing-wrapper .event-list-description-place {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.taxonomy-listing-wrapper .event-list-description-name {
  color: var(--content-neutral-primary, #222732);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.taxonomy-listing-wrapper .title-wrapper {
  display: flex;
  gap: 8px;
  color: var(--content-neutral-primary, #222732);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.taxonomy-listing-wrapper .ant-list-split .ant-list-item {
  border-bottom: none;
}

.taxonomy-listing-wrapper .ant-tabs-nav {
  margin-bottom: 0;
}

.taxonomy-listing-wrapper .invitation-details-text-prologue {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.taxonomy-listing-wrapper .events-heading {
  font-weight: 700;
  white-space: nowrap;
  font-size: 34px;
}

.taxonomy-listing-wrapper .event-add-button {
  position: absolute;
  left: 100%;
}
.taxonomy-listing-wrapper .events-content {
  padding-top: 24px;
}

.taxonomy-listing-wrapper .filter-buttons {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 600;
  font-size: 16px;
}

.taxonomy-listing-wrapper .filter-sort-button {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 400;
  font-size: 16px;
}

.taxonomy-listing-wrapper .filter-sort-dropdown-wrapper {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}
.taxonomy-listing-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected {
  background-color: #eff2ff;
  color: #0f0e98;
}
.taxonomy-listing-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected::after {
  content: '\2713';
}

.taxonomy-listing-wrapper .ant-popover-inner {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}

.taxonomy-listing-wrapper .add-btn-container {
  justify-content: flex-end;
}

.taxonomy-listing-wrapper .sort-option-row > span,
.taxonomy-listing-wrapper .sort-option-row > button {
  margin-right: 8px;
}

.taxonomy-listing-wrapper .event-list-item-wrapper {
  gap: 14px;
}

.taxonomy-listing-wrapper .ant-list-vertical .event-list-status {
  align-items: flex-start;
}

@media only screen and (max-width: 480px) {
  .taxonomy-listing-wrapper .events-heading-wrapper .events-heading {
    font-size: 24px;
    margin-bottom: 8px;
    white-space: normal;
  }
  .taxonomy-listing-wrapper .events-content {
    padding: 16px;
  }

  .taxonomy-listing-wrapper .add-btn-container {
    margin-left: auto;
    margin-bottom: 8px;
  }
}
