.create-new-entity-page .button-container .ant-btn {
  color: #0f0e98;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  width: fit-content;
  cursor: pointer;
}

.create-new-entity-page .heading {
  font-size: 34px;
  font-weight: 700;
}

.create-new-entity-page .sub-heading {
  font-size: 24px;
  font-weight: 700;
}

.create-new-entity-page .content {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 4px;
}
.create-new-entity-page .content .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #646d7b;
}
.create-new-entity-page .content .search p {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .create-new-entity-page .content .text {
    font-weight: 700;
  }
}

@media (max-width: 480px) {
  .create-new-entity-page .sub-heading {
    font-size: 20px;
  }

  .create-new-entity-page .heading {
    font-size: 24px;
  }
}
