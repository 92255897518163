.form-item-input {
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
}
.form-item-input .ant-input-group-addon:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f7f7f7;
  color: #9196a3;
  font-weight: 400;
  font-size: 16px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #b6c1c9;
}

.form-item-input .ant-input-group > .ant-input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #b6c1c9;
}
.form-item-input .ant-input-group-addon:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #f7f7f7;
  color: #9196a3;
  font-weight: 400;
  font-size: 16px;
  border-width: 0px;
  border-style: solid;
  border-color: #b6c1c9;
}

.form-item-input .ant-input-group > .ant-input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media screen and (max-width: 480px) {
  .form-item-input .ant-input-group-addon:first-child {
    font-size: 12px;
  }
  .form-item-input .ant-input-group-addon:last-child {
    font-size: 12px;
  }
  .form-item-input .ant-input-group > .ant-input:first-child {
    font-size: 12px;
  }
  .form-item-input .ant-input-group > .ant-input:last-child {
    font-size: 12px;
  }
}
