.events-wrapper .events-heading {
  font-weight: 700;
  white-space: nowrap;
  font-size: 34px;
}

.events-wrapper .event-add-button {
  position: absolute;
  left: 100%;
}
.events-wrapper .events-content {
  padding-top: 24px;
}

.events-wrapper .filter-buttons {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 600;
  font-size: 16px;
}

.events-wrapper .filter-sort-button {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 400;
  font-size: 16px;
}

.events-wrapper .filter-sort-dropdown-wrapper {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}
.events-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected {
  background-color: #eff2ff;
  color: #0f0e98;
}
.events-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected::after {
  content: '\2713';
}

.events-wrapper .ant-popover-inner {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}

.date-filter-popover {
  padding-top: 0px;
}
.date-filter-popover .ant-popover-content {
  min-width: 200px;
}

.date-filter-popover .ant-popover-arrow {
  display: none;
}

.date-filter-popover .ant-popover-inner-content {
  padding: 0px;
}
.date-filter-popover .date-range-picker-filter-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-height: 48px;
}
.date-filter-popover .date-range-picker-filter-footer-label {
  font-weight: 600;
  font-size: 16px;
  color: #1b3de6;
}

.date-filter-popover .date-range-picker-filter-footer-button-selected {
  background-color: #eff2ff;
}

.date-filter-popover .ant-popover-content .ant-radio-inner::after {
  background-color: #1b3de6;
}

.date-filter-popover .ant-popover-content .ant-radio-inner {
  border-color: #1b3de6;
}

@media only screen and (max-width: 768px) {
  .events-wrapper .events-heading-wrapper .events-heading {
    font-size: 24px;
    white-space: normal;
  }
  .events-wrapper .events-content {
    padding-top: 0;
  }

  .events-wrapper > .events-wrapper-cloumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .events-wrapper .filter-container .event-filter-item-mobile-full-width .searchable-checkbox-dropdown-wrapper {
    width: calc(100% - 32px);
    margin: auto;
  }

  .events-wrapper .filter-container .event-filter-item-mobile-full-width .searchable-checkbox-overlay {
    width: calc(100vw - 80px);
    margin-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .events-wrapper .filter-container .event-filter-item-mobile-full-width .searchable-checkbox-overlay {
    width: calc(100vw);
  }
}
