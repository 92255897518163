.time-picker-wrapper {
  width: 100%;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #222732;
}

.time-picker-dropdown {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
  font-weight: 400;
  font-size: 16px;
}

.time-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell {
  color: #222732;
}
.time-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: #0f0e98;
  background-color: #eff2ff;
}

.time-picker-dropdown .ant-picker-footer .ant-btn-primary {
  background: #1b3de6;
  border-radius: 4px;
}
