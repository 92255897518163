.quick-create-place-modal-title {
  font-weight: 700;
  font-size: 20px;
}

.quick-create-place-modal-wrapper .ant-form-item-label > label,
.quick-create-place-modal-wrapper .quick-create-place-modal-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
}

.quick-create-place-modal-wrapper .quick-create-place-modal-label::after {
  display: inline-block;
  margin-left: 4px;
  color: #f43131;
  font-size: 14px;
  font-family: 'Roboto';
  line-height: 1;
  content: '*';
}

.quick-create-place-modal-wrapper .title-required:after {
  margin-left: 4px;
  content: '*';
  color: #f43131;
}

.quick-create-place-modal-wrapper .quick-create-place-modal-sub-heading {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}

.quick-create-place-modal-wrapper .ant-dropdown-menu-item-selected {
  color: #0f0e98;
  background-color: #eff2ff;
}

.quick-create-place-modal-wrapper
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #f43131;
  font-size: 14px;
  font-family: 'Roboto';
  line-height: 1;
  content: '*';
}

.quick-create-place-modal-wrapper
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
