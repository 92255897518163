.read-only-wrapper .read-only-event-heading h4 {
  font-weight: 700;
  font-size: 34px;
  color: #222732;
  font-family: 'Roboto';
  font-style: normal;
}

.read-only-wrapper .read-only-event-section-wrapper {
  padding: 24px;
  background-color: #ffffff;
}

.read-only-wrapper .iframe-video-embed {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.read-only-wrapper .read-only-event-section-col {
  background-color: #ffffff;
  border-radius: 4px;
}
.read-only-wrapper .read-only-event-content {
  font-weight: 700;
  font-size: 16px;
  color: #222732;
}
.read-only-wrapper .read-only-event-content-title {
  font-weight: 700;
  font-size: 24px;
  color: #222732;
}
.read-only-wrapper .read-only-event-content-sub-title-primary {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}
.read-only-event-content .read-only-event-content-date {
  font-weight: 400;
  font-size: 16px;
  color: #222732;
}

.read-only-wrapper .read-only-event-content-sub-title-secondary {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}

.read-only-wrapper .url-links {
  font-weight: 600;
  font-size: 16px;
  color: #1b3de6;
  text-decoration-line: underline;
  margin-bottom: 32px;
  word-break: break-all;
}
.read-only-wrapper .ticket-price-table {
  width: 100%;
}
.read-only-wrapper .ticket-price-table td,
th {
  text-align: left;
}

.read-only-wrapper .recurring-day-buttons {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 600;
  font-size: 12px;
  cursor: default;
}

.read-only-wrapper .events-readonly-artsdata-link-wrapper {
  padding: 0 !important;
}

.events-read-only-wrapper .read-only-detail-heading {
  font-size: 24px;
}

.events-read-only-wrapper .ant-select-arrow {
  display: none;
}

.events-read-only-wrapper .events-content {
  flex-wrap: wrap-reverse;
  gap: 24px;
  padding: 16px 0px;
}

.events-read-only-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 0px;
}

.events-read-only-wrapper .top-level-column {
  padding: 0px !important;
}

@media only screen and (max-width: 480px) {
  .events-read-only-wrapper .read-only-event-heading h4 {
    font-size: 24px;
  }

  .events-read-only-wrapper .read-only-event-content {
    font-size: 12px;
  }

  .read-only-event-content .events-read-only-wrapper .read-only-detail-heading {
    font-size: 20px;
  }

  .events-read-only-wrapper .read-only-event-content-sub-title-primary {
    font-size: 12px;
    font-weight: 700;
  }

  .events-read-only-wrapper .url-links {
    font-size: 12px;
  }

  .events-read-only-wrapper .top-level-column {
    padding: 0px !important;
  }

  .events-read-only-wrapper .tags-wrapper {
    font-size: 10px;
  }

  .read-only-wrapper .read-only-event-content-sub-title-primary {
    font-size: 12px;
  }

  .read-only-wrapper .read-only-event-content-sub-title-secondary {
    font-size: 12px;
  }
}
