.floating-modal-wrapper,
.aside-content-wrapper {
  display: none;
}

.change-type-popover {
  min-width: 250px;
}

.change-type-popover .ant-popover-arrow {
  display: none;
}

.floating-modal-wrapper .change-type-icon {
  font-size: 16px;
  color: #1b3de6;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: #eff2ff;
  border-radius: 4px;
  display: grid;
  place-content: center;
}

.floating-modal-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  .aside-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px;
    margin-top: 12%;
  }

  .floating-modal-wrapper {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 923px), screen and (min-width: 991px) and (max-width: 1099px) {
  .aside-content-wrapper {
    margin-top: 0;
  }
}
