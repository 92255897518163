.change-password-modal .ant-modal-title {
  color: var(--content-neutral-primary, #222732);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.change-password-modal .ant-modal-close-x {
  font-size: 20px;
}

.change-password-modal .cancel-button {
  border-radius: 4px;
  background: var(--background-neutrals-transparent, rgba(255, 255, 255, 0));
  display: flex;
  padding: 8px 16px;
  height: 40px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: none;
  gap: 8px;
  color: var(--content-action-default, #1b3de6);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.change-password-modal .ok-button {
  border-radius: 4px;
  background: var(--content-action-default, #1b3de6);
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 40px;
  width: fit-content;
  color: var(--background-neutrals-ground, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.change-password-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--content-neutral-stroke, #b6c1c9);
  background: var(--background-neutrals-ground, #fff);
  display: flex;
  height: 72px;
  justify-content: flex-end;
  align-items: center;
}
