.text-editor {
  max-width: 100%;
  width: 423px;
  border: 4px solid #e8e8e8;
  font-family: 'Roboto';
  border-radius: 4px;
}

.text-editor .ql-toolbar.ql-snow,
.text-editor .ql-container.ql-snow {
  border: none;
}

.text-editor .ql-toolbar.ql-snow {
  background-color: #f7f7f7;
}

.text-editor .ql-editor {
  resize: vertical;
}

.text-editor .ql-container {
  overflow: visible;
}

@media (max-width: 480px) {
  .text-editor .ql-snow .ql-editor h1 {
    font: 1.2em 'Roboto';
  }

  .text-editor .ql-container {
    font-size: 12px;
  }
}
