.add-event-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #1b3de6;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
}
.add-event-button:hover {
  background-color: #0f0e98;
  border-color: #0f0e98;
}

.add-event-button[disabled],
.add-event-button[disabled]:hover {
  background-color: #9196a3;
  border-color: #9196a3;
  color: #ffffff;
}
