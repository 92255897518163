.arts-data-info {
  background-color: #e3e8ff;
  color: #0f0e98;
  min-height: 32px;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  padding: 8px;
  border-radius: 4px;
}

.arts-data-info .arts-data-title {
  cursor: pointer;
}

.arts-data-info .ant-badge-status-dot {
  height: 2px;
  width: 2px;
}
