.add-edit-wrapper .add-edit-event-heading h4 {
  font-weight: 700;
  font-size: 34px;
  color: #222732;
  font-family: 'Roboto';
  font-style: normal;
}
.add-edit-wrapper .add-event-button-wrap {
  display: flex;
  gap: 16px;
}

.add-edit-wrapper .iframe-video-embed {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.add-edit-wrapper .add-entity-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
}

.add-edit-wrapper .ant-form-item-label > label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
}
.add-edit-wrapper
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.add-edit-wrapper
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #f43131;
  font-size: 14px;
  font-family: 'Roboto';
  line-height: 1;
  content: '*';
}
.add-edit-wrapper .add-event-date-wrap {
  font-weight: 700;
  font-size: 24px;
  color: #222732;
  margin-bottom: 24px;
}
.add-edit-wrapper .title-required:after {
  margin-left: 4px;
  content: '*';
  color: #f43131;
}
.add-edit-wrapper .add-event-date-heading {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}
.add-edit-wrapper .date-buttons,
.add-edit-wrapper .ticket-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.add-edit-wrapper .add-event-section-wrapper {
  padding: 24px;
  background-color: #ffffff;
}

.add-edit-wrapper .add-event-section-col {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.add-edit-wrapper .event-description-footer {
  font-size: 16px;
  color: #646d7b;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.add-edit-wrapper .subheading-wrap .ant-form-item-label > label {
  font-size: 12px;
}

.add-edit-wrapper .ant-popover-inner {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}
.add-edit-wrapper .event-popover .ant-popover-inner-content {
  padding: 0px;
}
.add-edit-wrapper .ant-popover-inner-content .search-scrollable-content {
  max-height: 350px;
  overflow-y: scroll;
  padding: 12px;
}

.add-edit-wrapper .event-popover {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.add-edit-wrapper .event-popover .ant-popover-arrow {
  display: none;
}
.add-edit-wrapper .event-popover-options {
  cursor: pointer;
}

.add-edit-wrapper .event-popover-options:focus > .selection-item-wrapper {
  background: #eff2ff;
}

.add-edit-wrapper .event-popover-options:focus-visible,
.add-edit-wrapper .event-popover-options:focus > .selection-item-wrapper:focus-visible {
  outline: none;
}

/* .add-edit-wrapper .event-popover-options .selection-item-wrapper:focus-visible {
  outline: none;
}

.add-edit-wrapper .event-popover-options:focus-visible {
  outline: none;
} */

.add-edit-wrapper .recurring-day-buttons {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 600;
  font-size: 12px;
}

.add-edit-wrapper .event-popover-options:hover,
.add-edit-wrapper .event-popover-options-active {
  background: #eff2ff;
}

.event-popover-options-active-hover > .selection-item-wrapper {
  background: #eff2ff;
}

.add-edit-wrapper .quick-create {
  background-color: #eff2ff;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #1b3de6;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  height: 40px;
  padding: 12px;
}

.add-edit-wrapper .popover-section-header {
  padding: 6px 16px 4px 8px;
  font-weight: 700;
  color: #646d7b;
}

.add-edit-wrapper .add-event-section-col .url .ant-input-group-wrapper {
  width: 100% !important;
}

.add-edit-wrapper .ticket-link-select .ant-select-selector {
  background-color: #f7f7f7;
  color: #9196a3;
}

.add-edit-wrapper .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.event-form-wrapper .eventAccessibility .ant-select {
  width: 100% !important;
  margin-bottom: 4px;
}

.event-form-wrapper .events-search,
.event-form-wrapper .date-range-picker-wrapper {
  width: 100% !important;
}

.event-form-wrapper .responsive-view-control-class .aside-content-wrapper {
  padding: 0px;
}

.event-form-wrapper .language-literal-banner .ant-alert-message {
  color: #1572bb;
}

.fallback-modal-container .ant-modal-confirm-content > div {
  background: #fffbe5 !important;
}

.fallback-modal-container .ant-modal-confirm-content > div :last-child {
  color: #8c7911 !important;
}

.fallback-modal-container .ant-modal-confirm-btns button:last-child {
  background: #1b3de6 !important;
  border-color: #1b3de6;
}

@media screen and (max-width: 480px) {
  .add-edit-wrapper .add-edit-event-heading h4 {
    font-size: 24px;
  }

  .add-edit-wrapper .outlined-button {
    padding: 8px 16px;
    height: 40px;
  }

  .add-edit-wrapper .add-entity-label,
  .add-edit-wrapper .event-description-footer,
  .add-edit-wrapper .add-event-date-heading,
  .add-edit-wrapper .ant-form-item-label > label {
    font-size: 12px;
  }

  .add-edit-wrapper .add-event-section-wrapper {
    padding: 16px;
  }

  .add-edit-wrapper .tags-wrapper {
    font-size: 10px;
    padding: 4px 8px;
  }

  .event-form-wrapper .arts-data-info {
    margin-top: 8px;
  }

  .event-form-wrapper .add-edit-event-heading h4,
  .event-form-wrapper .add-event-button-wrap > * {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 575px) {
  .add-edit-wrapper .add-event-section-col {
    padding: 0px;
    margin: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .event-form-wrapper .responsive-view-control-class {
    position: absolute;
    right: 0;
    margin-top: 16px;
  }
}
