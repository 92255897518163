.sidebar-wrapper {
  box-sizing: border-box;
  padding: 24px 16px 0px;
  gap: 24px;
  background: #ffffff;
  border-right: 1px solid #b6c1c9;
}

.sidebar-wrapper .sidebar-menu-item {
  border-radius: 8px;
  font-size: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 9px;
}
.sidebar-wrapper .sidebar-main-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #eff2ff;
  font-weight: 700;
  font-size: 16px;
  color: #0f0e98;
}
.sidebar-wrapper .sidebar-calendar:hover {
  background-color: #f9faff;
}

.sidebar-wrapper .sidebar-main-menu {
  margin-top: 24px;
}

.sidebar-wrapper
  .sidebar-main-menu
  .ant-menu-light
  .ant-menu-item:hover:not(.sidebar-wrapper .sidebar-main-menu .ant-menu-item-selected) {
  background-color: #f9faff;
  color: #1b3de6;
}

.sidebar-wrapper .sidebar-calendar {
  gap: 8px;
  height: 48px;
  background: rgba(255, 255, 255, 0.005);
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #b6c1c9;
  border-radius: 8px;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 8px;
}

.sidebar-wrapper .sidebar-calendar > span {
  margin-left: 0;
}

.sidebar-wrapper .sidebar-calendar .calendar-icon-container {
  display: grid;
  place-content: center;
}

.sidebar-calendar-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}
@media only screen and (max-width: 600px) {
  .sidebar-wrapper {
    display: none;
  }
}
