.dashboard-header {
  padding: 0;
  height: 72px;
}

.dashboard-wrapper {
  height: 100%;
}

.read-only-wrapper .artsdata-link-wrapper {
  margin-left: 16px;
  padding: 0 !important;
}

.dashboard-wrapper .site-layout-background {
  padding: 34px 32px 32px 32px;
  margin: 0;
  min-height: 280px;
  overflow-y: scroll;
  background: #f9faff;
}

.calendar-read-only-modal .ant-modal-header .calendar-read-only-title {
  font-weight: 700;
  font-size: 20px;
  color: #222732;
}

.calendar-read-only-modal .ant-modal-body .calendar-read-only-content {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}
@media only screen and (max-width: 428px) {
  .dashboard-wrapper .site-layout-background {
    padding: 16px;
    margin: 0;
    min-height: 280px;
    overflow-y: scroll;
    background: #f9faff;
  }
}
