.sidebar-navigation-menu-responsive-drawer .sidebar-menu-item {
  border-radius: 8px;
  font-size: 16px;
  height: 56px;
  display: flex;
  align-items: center;
}

.sidebar-navigation-menu-responsive-drawer
  .sidebar-main-menu-repsonsive
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #eff2ff;
  font-weight: 700;
  font-size: 16px;
  color: #0f0e98;
}

.sidebar-navigation-menu-responsive-drawer .sidebar-calendar {
  gap: 8px;
  height: 48px;
  background: rgba(255, 255, 255, 0.005);
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #b6c1c9;
  border-radius: 8px;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 8px;
}

.sidebar-navigation-menu-responsive-drawer .sidebar-calendar .ant-menu-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sidebar-navigation-menu-responsive-drawer .ant-drawer-extra {
  margin-left: 17px;
}

.sidebar-navigation-menu-responsive-drawer .ant-drawer-header {
  border-bottom: none;
}

.sidebar-navigation-menu-responsive-drawer .ant-drawer-footer {
  border: none;
}

.sidebar-navigation-menu-responsive-drawer .username-responsive {
  text-transform: lowercase;
}

.sidebar-navigation-menu-responsive-drawer .ant-drawer-footer .username-responsive {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.sidebar-navigation-menu-responsive-drawer .ant-drawer-footer .user-details-responsive:first-child div {
  display: flex;
  align-items: center;
  min-height: 32px;
}
