.upload-wrapper {
  background: #ffffff !important;
  border: 1px dashed #b6c1c9 !important;
}

.image-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: auto;
  padding: 8px;
  margin-top: 8px;
}
.image-footer .image-contents,
.image-footer .image-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image-footer .image-contents {
  display: flex;
  align-items: center;
  height: 100%;
  width: 85%;
}

.image-footer .image-thumbnail {
  width: auto;
  min-width: none;
}

.image-footer .image-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  margin-left: 10px;
}

.image-footer .image-actions {
  cursor: pointer;
}
.upload-wrapper .image-footer .image-name {
  /* white-space: nowrap; */
  cursor: pointer;
  font-size: 14px;
}
.image-footer .image-options-more-icon:hover {
  background-color: #eff2ff;
  border-radius: 4px;
  padding: 4px;
}

.image-footer .image-thumbnail {
  display: block;
  height: 48px;
  min-height: 48px;
  overflow: hidden;
}

.image-footer .edit-image {
  border: 1px solid #607efc;
  border-radius: 2px;
  padding: 2px 4px;
}

.upload-wrapper .ant-upload-drag-container .upload-helper-text {
  color: #646d7b;
  font-weight: 400;
  font-size: 16px;
}

.image-footer .image-contents .image-name-wrapper {
  display: flex;
  flex-direction: column;
}

.image-footer .image-contents .image-name-wrapper .image-credits {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

@media (max-width: 480px) {
  .image-footer .image-name {
    text-wrap: wrap;
    font-size: 12px;
  }

  .image-footer .image-actions .span-remove-image > span,
  .image-footer .image-actions .span-edit-image > span {
    font-size: 12px;
  }

  .upload-wrapper .ant-upload-drag-container .upload-helper-text {
    font-size: 12px;
  }

  .upload-wrapper .ant-upload {
    padding: 8px 0 !important;
  }
}
