.date-range-picker-wrapper {
  border: 1px solid #b6c1c9;
  border-radius: 4px;
}

.date-range-picker-wrapper .ant-picker-suffix {
  color: #1b3de6;
}

.date-range-picker-wrapper .ant-picker-clear {
  background: #ffffff;
  color: #1b3de6;
}

.date-range-picker-wrapper .ant-picker-active-bar {
  display: none;
}

.date-range-picker-calendar .ant-picker-cell-range-start .ant-picker-cell-inner,
.date-range-picker-calendar .ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #607efc;
  border-radius: 4px;
}

.date-range-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner,
.date-range-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 4px;
}
.date-range-picker-calendar .ant-picker-range-wrapper .ant-picker-panel-container {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.date-range-picker-calendar .ant-picker-header-view {
  color: #222732;
  font-weight: 700;
  font-size: 16px;
}

.date-range-picker-calendar .ant-picker-date-panel .ant-picker-content th {
  text-align: center;
}

.date-range-picker-calendar .ant-picker-panel-container .ant-picker-panels {
  border-top: 1px solid #b6c1c9;
  border-bottom: 1px solid #b6c1c9;
}

@media (max-width: 768px) {
  .date-range-picker-calendar .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .date-range-picker-calendar .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn,
  .date-range-picker-calendar .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .date-range-picker-calendar .ant-picker-panel .ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .date-range-picker-calendar .ant-picker-presets {
    display: none !important;
  }
}
