.selection-item-wrapper {
  width: 423px;
  align-items: center;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0px 8px 0px;
  min-height: 68px;
  background-color: #f9faff;
}
.selection-item-list-wrapper {
  padding: 0px;
  align-items: center;
}
.selection-item-wrapper .ant-list-item-meta {
  align-items: center;
}
.selection-item-wrapper .ant-list-item-meta-title {
  line-height: 1;
}

.selection-item-list-wrapper .selection-item-title {
  font-weight: 700;
  font-size: 16px;
  color: #222732;
  display: flex;
  gap: 8px;
  align-items: center;
}

.selection-item-list-wrapper .selection-item-title .literal-badge {
  position: inherit;
}

.selection-item-list-wrapper .ant-list-item-action-split {
  display: none;
}

.selection-item-list-wrapper .selection-item-subheading,
.selection-item-wrapper .selection-item-sub-content {
  font-weight: 400;
  font-size: 12px;
  color: #646d7b;
}
.selection-item-wrapper .selection-item-sub-title {
  font-weight: 700;
  font-size: 12px;
}

.selection-item-wrapper .open-hour-url-link {
  text-decoration: underline;
}

.selection-item-wrapper .arts-data-link {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background: #e3e8ff;
  color: #0f0e98;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
}

.selection-item-wrapper .arts-data-link .arts-data-link-content {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 2px;
}

.selection-item-wrapper .arts-data-link .arts-data-link-first-line {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media screen and (max-width: 480px) {
  .selection-item-list-wrapper .selection-item-subheading,
  .selection-item-wrapper .selection-item-sub-content,
  .selection-item-wrapper .arts-data-link {
    font-size: 10px;
  }

  .selection-item-list-wrapper .selection-item-title {
    font-size: 12px;
  }

  .selection-item-wrapper .ant-avatar-lg.ant-avatar-icon {
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
  }

  .selection-item-wrapper .ant-avatar-lg.ant-avatar-icon > span {
    font-size: 16px;
  }
}
