.primary-button {
  height: 40px;
  background-color: #1b3de6;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.primary-button:hover {
  background-color: #0f0e98;
  border-color: #0f0e98;
}
