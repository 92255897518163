.global-delete-modal-container .ant-modal-close-x {
  margin-top: 5px;
}

.global-delete-modal-container {
  width: 500px !important;
  border-radius: 8px;
  /* box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2); */
}

.global-delete-modal-container .ant-modal-body {
  padding: 0 !important;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
}

.global-delete-modal-container .ant-modal-confirm-title {
  padding: 18px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--content-neutral-stroke, #b6c1c9);
  background: var(--background-neutrals-ground, #fff);
}

.global-delete-modal-container .ant-modal-confirm-title span {
  color: var(--content-neutral-primary, #222732);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.global-delete-modal-container .ant-modal-confirm-content {
  padding: 32px;
}

.global-delete-modal-container .ant-modal-confirm-content > div {
  display: flex;
  gap: 8px;
  border-radius: 4px;
  background: #ffcfcf;
  padding: 16px;
}

.global-delete-modal-container .ant-modal-confirm-content > div :first-child {
  margin-top: 2px;
}
.global-delete-modal-container .ant-modal-confirm-content > div :last-child {
  color: var(--content-alert-error-darker, #ce1111);
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.global-delete-modal-container .ant-modal-confirm-btns {
  margin: 0;
}

.global-delete-modal-container .ant-modal-confirm-btns {
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--content-neutral-stroke, #b6c1c9);
  background: var(--background-neutrals-ground, #fff);
  display: flex;
  height: 72px;
  padding: 16px 16px 16px 16px;
  justify-content: flex-end;
  align-items: center;
}

.global-delete-modal-container .ant-modal-confirm-btns button:last-child {
  border-radius: 4px;
  background: var(--content-alert-error-darker, #ce1111);
  color: var(--background-neutrals-ground, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.global-delete-modal-container .ant-modal-confirm-btns button:first-child {
  border-radius: 4px;
  background: var(--background-neutrals-transparent, rgba(255, 255, 255, 0));
  border: none;
  color: var(--content-action-default, #1b3de6);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
