.users-list-item-wrapper {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
}

.users-list-item-wrapper .user-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.users-list-item-wrapper:hover {
  background-color: #f9faff;
}

@media screen and (max-width: 480px) {
  .user-management-wrapper .event-list-wrapper {
    width: 100%;
  }
}
