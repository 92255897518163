.settings-wrapper .event-list-description-place {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.settings-wrapper .title-wrapper {
  display: flex;
  gap: 8px;
  color: var(--content-neutral-primary, #222732);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.settings-wrapper .title-wrapper > .name {
  overflow-wrap: anywhere;
}

.settings-wrapper .title-wrapper .event-list-status-userdetail {
  overflow-wrap: anywhere;
  text-align: left;
}

.settings-wrapper .users-list-item-wrapper .invitation-details {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
}

.settings-wrapper .users-list-item-wrapper .invitation-details .invitation-details-text-prolouge {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.settings-wrapper .users-list-item-wrapper .invitation-details .invitation-details-username {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.settings-wrapper .users-list-item-wrapper .user-item-content .tags-wrapper {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.settings-wrapper .users-list-item-wrapper .ant-list-item-meta-description {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.settings-wrapper .ant-list-split .ant-list-item {
  border-bottom: none;
}

.settings-wrapper .users-list-item-wrapper .ant-tag {
  margin-right: 0;
}

.settings-wrapper .users-list-item-wrapper .ant-list-item-action {
  margin-left: 24px;
}

.settings-wrapper .ant-tabs-nav {
  margin-bottom: 0;
}

.user-management-wrapper {
  padding-top: 24px;
}

.user-management-wrapper .invitation-details-text-prologue {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.user-management-wrapper .events-heading {
  font-weight: 700;
  white-space: nowrap;
  font-size: 34px;
}

.user-management-wrapper .event-add-button {
  position: absolute;
  left: 100%;
}
.user-management-wrapper .events-content {
  padding-top: 24px;
}

.user-management-wrapper .filter-buttons {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 600;
  font-size: 16px;
}

.user-management-wrapper .filter-sort-button {
  background-color: #f9faff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  color: #222732;
  font-weight: 400;
  font-size: 16px;
}

.user-management-wrapper .filter-sort-dropdown-wrapper {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}
.user-management-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected {
  background-color: #eff2ff;
  color: #0f0e98;
}
.user-management-wrapper .filter-sort-dropdown-wrapper .ant-dropdown-menu-item-selected::after {
  content: '\2713';
}

.user-management-wrapper .ant-popover-inner {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
}

.user-management-wrapper .add-btn-container {
  justify-content: flex-end;
}

.user-management-wrapper .sort-option-row > span,
.user-management-wrapper .sort-option-row > button {
  margin-right: 8px;
}

.user-management-wrapper .ant-list-vertical .user-item-content {
  width: fit-content;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .user-management-wrapper .users-list-item-wrapper {
    padding: 24px 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .user-management-wrapper .events-heading-wrapper .events-heading {
    font-size: 24px;
    white-space: normal;
  }
  .user-management-wrapper .events-content {
    padding: 16px;
  }
  .user-management-wrapper .events-heading-wrapper {
    width: 200px;
  }

  .user-management-wrapper .users-list-item-wrapper {
    padding: 16px 0px !important;
  }

  .user-management-wrapper .ant-list-vertical .user-item-content .tags-wrapper {
    width: fit-content;
  }

  .user-management-wrapper .user-search-wrapper {
    margin-right: 0px !important;
  }
}
