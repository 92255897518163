.add-taxonomy-wrapper .add-Taxonomy-heading > h4 {
  color: var(--content-neutral-primary, #222732);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.add-taxonomy-wrapper .taxonomy-information-card .ant-tabs-content .ant-form-item {
  margin-bottom: 8px;
}

.add-taxonomy-wrapper .userAccess-label {
  padding-bottom: 8px;
}

.add-taxonomy-wrapper .add-entity-label,
.ant-form-item-label > label,
.add-taxonomy-wrapper .userAccess-label {
  color: #222732;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.add-taxonomy-wrapper .language-literal-banner .ant-alert-message {
  color: #1a64a7;
}

.add-taxonomy-wrapper .field-description {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.add-taxonomy-wrapper .classType .ant-dropdown-trigger,
.add-taxonomy-wrapper .user-access .ant-dropdown-trigger {
  border-radius: 4px;
  border: 1px solid var(--content-neutral-stroke, #b6c1c9);
  background: var(--background-neutrals-ground, #fff);
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  color: var(--content-neutral-primary, #222732);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.add-taxonomy-wrapper .user-access .ant-checkbox-group,
.add-taxonomy-wrapper .user-access .ant-dropdown-trigger,
.add-taxonomy-wrapper .user-access .ant-space {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.add-taxonomy-wrapper .name-checkbox {
  color: var(--content-neutral-primary, #222732);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.add-taxonomy-wrapper .name-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1b3de6;
  border-color: #222732;
}

.add-taxonomy-wrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #222732;
}

.add-taxonomy-wrapper .concept-card .heading-concepts {
  color: var(--content-neutral-primary, #222732);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.add-taxonomy-wrapper .concept-card .text-concepts {
  color: var(--content-neutral-secondary, #646d7b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.add-taxonomy-wrapper .concept-card .tag-header {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border: solid 4px #eff2ff;
  border-bottom: none;
  width: fit-content;
  border-radius: 4px 4px 0px 0px;
}

.add-taxonomy-wrapper .disabled-dropdown .ant-dropdown-trigger {
  border-radius: 4px;
  border: 1px solid var(--content-neutral-stroke, #b6c1c9);
  background: var(--background-neutrals-underground-0, #f7f7f7);
}

.add-taxonomy-wrapper .ant-form-item-required {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.add-taxonomy-wrapper .ant-form-item-label {
  width: fit-content;
}

.add-taxonomy-wrapper .ant-form-item {
  margin-bottom: 8px;
}

.add-taxonomy-wrapper .add-event-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add-taxonomy-wrapper .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  height: 16px !important;
}

.add-taxonomy-wrapper .add-event-section-col {
  padding: 0 !important;
  margin: 0 !important;
}

.add-taxonomy-wrapper .add-event-section-wrapper {
  padding: 24px;
}

@media screen and (max-width: 480px) {
  .add-taxonomy-wrapper .add-event-section-wrapper {
    padding: 16px;
  }

  .add-taxonomy-wrapper .add-Taxonomy-heading > h4 {
    font-size: 24px;
  }

  .add-taxonomy-wrapper .add-entity-label,
  .ant-form-item-label > label,
  .add-taxonomy-wrapper .field-description,
  .add-taxonomy-wrapper .ant-select-lg,
  .add-taxonomy-wrapper textarea.ant-input,
  .add-taxonomy-wrapper .select-wrapper-dropdown .ant-select-item-option,
  .add-taxonomy-wrapper .concept-card .text-concepts {
    font-size: 12px;
    line-height: 18px;
  }

  .add-taxonomy-wrapper .concept-card .heading-concepts {
    font-size: 20px;
  }

  .add-taxonomy-wrapper .classType .ant-dropdown-trigger,
  .add-taxonomy-wrapper .user-access .ant-dropdown-trigger {
    font-size: 12px;
    line-height: 18px;
  }

  .add-taxonomy-wrapper .searchable-checkbox-dropdown-wrapper .searchable-checkbox-dropdown-content {
    margin-top: 0px;
  }

  .add-taxonomy-wrapper .searchable-checkbox-dropdown-wrapper .searchable-checkbox-dropdown-content .ant-form label {
    font-size: 12px;
  }

  .add-taxonomy-wrapper .concept-card {
    margin: 0px !important;
  }

  .add-taxonomy-wrapper .concept-card .ant-btn > span + .anticon {
    margin-left: 0px;
    font-size: 20px !important;
  }
}
