.quick-select-modal-title {
  font-weight: 700;
  font-size: 20px;
}

.quick-select-modal-wrapper .quick-select-modal-sub-heading {
  font-weight: 400;
  font-size: 16px;
  color: #646d7b;
}
