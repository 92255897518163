.literal-badge {
  position: absolute;
  top: 9px;
  right: 0;
  color: red;
}

.literal-badge .tags-wrapper {
  height: 24px;
  padding: 4px 8px 4px 8px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #1b3de6;
  color: #0f0e98;
  text-transform: capitalize;
}
