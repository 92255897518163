.credit-tags-wrapper {
  background-color: #eff2ff;
  color: #0f0e98;
  font-weight: 600;
  font-size: 10px;
  border-radius: 4px;
  display: flex;
  gap: 0px;
  text-transform: none;
  height: 24px;
  align-items: center;
  padding: 4px 8px 4px 4px;
  width: fit-content;
}
