.add-organization-wrapper .button-container .ant-btn {
  color: #0f0e98;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  font-weight: 600;
}

.add-organization-wrapper .associated-with-section .associated-with-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.add-organization-wrapper .associated-with-section-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #222732;
  margin-bottom: 24px;
}

.add-organization-wrapper .associated-with-section .associated-with-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-organization-wrapper .events-search {
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .add-organization-wrapper .responsive-view-control-class {
    position: absolute;
    right: 0;
    margin-top: 16px;
  }
}

@media screen and (max-width: 575px) {
  .add-organization-wrapper .add-event-section-wrapper .events-search {
    width: 100% !important;
  }
}
