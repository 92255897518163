.date-picker-wrapper {
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  max-width: 100%;
}

.date-picker-wrapper .ant-picker-suffix {
  color: #1b3de6;
}

.date-picker-calendar .ant-picker-panel-container {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.date-picker-calendar .ant-picker-header-view {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #222732;
}

.date-picker-calendar .ant-picker-cell-selected .ant-picker-cell-inner {
  background: #607efc;
  border-radius: 4px;
}

.date-picker-calendar .ant-picker-header {
  border-bottom: 1px solid #b6c1c9;
}

.date-picker-calendar .ant-picker-header button {
  color: #222732;
}

.date-picker-calendar .ant-picker-date-panel .ant-picker-content th {
  text-align: center;
}
