.custom-table {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.custom-table .ant-table-wrapper {
  flex: 1;
}

.custom-table .table-row:hover,
.custom-table .table-row:hover td {
  background-color: #eff2ff !important;
}

.custom-table .ant-table-container table > thead > tr th {
  font-weight: 700;
}

.custom-table .ant-table-tbody .ant-table-cell > div:hover {
  cursor: text;
}

.custom-table .ant-table-tbody .indent-level-0 {
  margin-left: 0;
}
.custom-table .ant-table-tbody .indent-level-1 {
  margin-left: 4px;
}
.custom-table .ant-table-tbody .indent-level-2 {
  margin-left: 8px;
}
.custom-table .ant-table-tbody .indent-level-3 {
  margin-left: 12px;
}

.custom-table .ant-table-tbody .ant-table-cell > div {
  width: fit-content;
  display: flex;
  max-width: 80%;
  text-wrap: wrap;
}

.custom-table .editable-row {
  cursor: grab !important;
}

.custom-table .editable-row:active {
  cursor: grabbing !important;
}

.custom-table .ant-table-tbody .drop-over-downward,
.drop-over-upward {
  transition: transform 0.2s ease, background-color 0.2s ease;
  transform: scale(1.02);
  background-color: rgba(24, 144, 255, 0.1);
}

.custom-table .ant-table-tbody .drop-over-upward,
.custom-table .ant-table-tbody .drop-over-upward-in-gap {
  transition: transform 0.2s ease, background-color 0.2s ease;
  transform: scale(1.02);
  background-color: rgba(24, 144, 255, 0.1);
}

.custom-table .ant-table-tbody .ant-table-cell > div .icon-container {
  padding: 0px 8px;
  margin-left: -12px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.custom-table .ant-table-tbody .ant-table-cell > div .icon-container .anticon {
  font-size: 16px;
}

.custom-table .ant-table-tbody .ant-table-cell .literal-badge {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.custom-table .ant-table-tbody .sort-handle-column {
  width: 20px;
}

.custom-table tr.drop-over-upward td {
  border-top: 2px dashed #1b3de6;
}

.custom-table .drop-over-upward-in-gap td {
  border-top: 2px dashed #1b3de6;
  border-bottom: 2px dashed #1b3de6;
}

.custom-table .drop-over-upward-in-gap td {
  border-top: 2px dashed #1b3de6;
  border-bottom: 2px dashed #1b3de6;
}

.custom-table .drop-over-upward-in-gap td:first-child {
  border-left: 2px dashed #1b3de6;
}

.custom-table .drop-over-upward-in-gap td:last-child {
  border-right: 2px dashed #1b3de6;
}

.custom-table .ant-table-tbody td .ant-input:focus,
.custom-table .ant-table-tbody td .ant-input-focused {
  border: 1px solid #b6c1c9;
}

.custom-table .ant-table-tbody td .ant-input-lg {
  font-size: 14px;
  line-height: 1.5;
}

.custom-table .outlined-button {
  display: flex;
  color: var(--content-action-default, #1b3de6);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  flex-direction: row-reverse;
  padding: 4px 4px;
  width: fit-content;
  margin-top: 20px;
}

.custom-table .outlined-button .outlined-label {
  font-size: 12px;
}
