.place-read-only .read-only-detail-heading {
  font-size: 24px;
}

.place-read-only .ant-select-arrow {
  display: none;
}

.place-read-only .events-content {
  flex-wrap: wrap-reverse;
  gap: 24px;
  padding: 16px 0px;
}

.place-read-only .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 0px;
}

.place-read-only .artsdata-link-wrapper {
  margin-left: 0px;
  padding: 0 16px !important;
}

.place-read-only .associated-with-section .associated-with-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.place-read-only .associated-with-section-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #222732;
  margin-bottom: 24px;
}

.place-read-only .associated-with-section .associated-with-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.place-read-only .place-read-only-image-section {
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .place-read-only .place-read-only-image-section .responsive-view-control-class {
    flex: 0 !important;
  }
}

@media only screen and (max-width: 480px) {
  .place-read-only .read-only-event-heading h4 {
    font-size: 24px;
  }

  .place-read-only .read-only-event-content {
    font-size: 12px;
  }

  .read-only-event-content .place-read-only .read-only-detail-heading {
    font-size: 20px;
  }

  .place-read-only .read-only-event-content-sub-title-primary {
    font-size: 12px;
    font-weight: 700;
  }

  .place-read-only .url-links {
    font-size: 12px;
  }

  .place-read-only .tags-wrapper {
    font-size: 10px;
    white-space: wrap;
    height: auto;
  }

  .read-only-wrapper .read-only-event-content-sub-title-primary {
    font-size: 12px;
  }

  .read-only-wrapper .read-only-event-content-sub-title-secondary {
    font-size: 12px;
  }
}

@media screen and (max-width: 575px) {
  .place-read-only .selection-item-wrapper {
    width: auto !important;
  }
}
