.back-to-login .back-to-login-icon {
  color: #1b3de6;
  width: 24px;
  height: 24px;
}

.back-to-login .back-to-login-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0f0e98;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 17px;
}

.reset-password-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  gap: 32px;
  position: relative;
}

.reset-password-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 0;
  max-width: 438px;
}

.reset-password-subheading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
  flex: none;
  flex-grow: 0;
  margin-bottom: 0;
}

.reset-password-form {
  max-width: 438px;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.reset-password-form .reset-password-form-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
}

.reset-password-form .reset-password-input-number-style {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b6c1c9;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.reset-password-input-number-style input {
  margin-left: -10px;
  font-size: 16px;
}

.reset-password-form .reset-password-reset-my-password {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1b3de6;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
}

.reset-password-form .reset-password-resend-code {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1b3de6;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.reset-password-reset-my-password .ant-row {
  width: 100%;
}
