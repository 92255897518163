.read-only-tab-layout-wrapper .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.read-only-tab-layout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #f9faff;
  color: var(--content-action-default, #1b3de6);
}

.read-only-tab-layout .ant-tabs-tab-btn {
  color: #646d7b;
  background: #f9faff;
}

.read-only-tab-layout .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid;
  border-bottom-color: var(--content-action-default, #1b3de6) !important;
  background: #f9faff;
  height: 103%;
}

.read-only-tab-layout .ant-tabs-nav-list {
  border-bottom: 2px solid #b6c1c9;
  width: 100%;
}

.read-only-tab-layout-wrapper {
  max-width: 100%;
  min-width: 100%;
}
