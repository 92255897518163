.customize-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customize {
  color: #0740d3;
  cursor: pointer;
}
.recurring-card {
  margin-bottom: 15px;
}
.time-zone-select.ant-select {
  min-width: 120px;
  color: #0740d3;
  max-width: 225px;
}
.time-zone-select.ant-select .ant-select-arrow {
  color: #0740d3;
}
.time-zone-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px 11px 0px 0px;
}
.timezone-item {
  margin: 0;
  margin-bottom: 20px;
}
.recurring-modal.ant-modal {
  width: 850px !important;
  max-height: 475px;
}
.custom-common-modal-container .calendar {
  padding: 1px;
  width: 300px;
  height: 296px;
  overflow-y: scroll;
}

.custom-common-modal-container .calendar .months-container .month-container {
  height: 230px;
}
.custom-common-modal-container .calendar table {
  width: 85%;
}
.custom-time-layout {
  display: flex;
  justify-content: space-between;
}
.custom-date-column {
  padding: 20px;
  height: 375px;
  overflow: auto;
}
.custom-no-of-date {
  font-size: 16px;
  font-weight: 600;
}
.custom-common-modal-container .ant-divider-horizontal {
  margin: 10px 0px;
}
.crud-icons .anticon {
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
}
.add-time-btn {
  font-size: 15px;
  font-weight: 600;
  color: #0206f3;
  text-align: center;
  margin: 15px;
}
.deleted-text {
  text-decoration-line: line-through;
  color: #b7b3b3;
}
.anticon.close-time {
  font-size: 12px;
  cursor: pointer;
}
.add-time-items .ant-btn {
  margin: 0px 10px;
  height: 33px;
  padding: 1.4px 15px;
}
.add-time-items {
  margin: 0px;
}
.check-time .ant-checkbox + span {
  font-weight: 600;
}
.copycheck.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.replace-txt,
.copycheck.ant-checkbox-group label {
  margin: 10px 0px;
  color: #000;
  font-size: 15px;
  font-weight: 600;
}
.select-all-check {
  color: blue;
  font-weight: 700;
}

.custom-modal-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-modal-title-wrapper .custom-modal-title-heading {
  color: #222732;
  font-weight: 700;
  font-size: 18px;
}

.custom-modal-title-wrapper .custom-modal-title-right-contents {
  display: flex;
  align-items: center;
}

.recurring-events-wrapper .frequency-selector .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px 11px 0px 11px;
  height: 40px;
  align-items: center;
}

.update-status-form .ant-form-item-label > label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222732;
}

.recurring-modal .calendar .calendar-header .year-neighbor {
  display: none !important;
}
.recurring-modal .calendar .calendar-header .year-neighbor2 {
  display: none !important;
}

@media screen and (max-width: 480px) {
  .custom-date-column {
    height: 200px;
  }

  .custom-modal-title-wrapper .custom-modal-title-right-contents {
    margin-top: 8px;
  }

  .custom-modal-title-wrapper .custom-modal-title-heading,
  .calendar .calendar-header .year-title {
    font-size: 16px;
  }

  .custom-modal-title-wrapper .custom-modal-title-heading {
    margin-right: 16px;
  }

  .custom-no-of-date,
  .custom-date-column .text-button-wrapper {
    font-size: 12px;
  }

  .custom-modal-title-wrapper {
    flex-wrap: wrap;
  }
}
