.custom-common-modal-container-wrapper .custom-common-modal-container .ant-modal-content {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: 100%;
}

.custom-common-modal-container .ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #b6c1c9;
}
.custom-common-modal-container .ant-modal-content .ant-modal-footer {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #b6c1c9;
}
