.artsdata-tag-wrapper {
  border-radius: 4px;
  padding: 4px 8px;
  width: auto;
  color: #0f0e98;
  background-color: #e3e8ff;
  border: 0px;
  margin: 0px;
  cursor: pointer;
}
